import { about, aboutMessage } from './about.module.scss';

export default function About() {
  return (
    <div className={about} id='about'>
      <img src='/images/landing/balloon.png' alt='speech-ballon' />
      <div className={aboutMessage}>
        <h2>O que é?</h2>
        <p>
          Constituída em 1999, a Associação Portuguesa para as Perturbações do Desenvolvimento e
          Autismo – Viseu tem como missão contribuir para a melhoria da qualidade de vida de forma
          multidisciplinar visando o desenvolvimento pessoal, emocional e social, a autonomia e a
          reabilitação das crianças, jovens e adultos com Perturbações do Desenvolvimento e
          Perturbações do Espetro do Autismo (PEA).{' '}
        </p>
        <p>
          Encaramos a nossa missão com entusiasmo e devoção, primando pela qualidade e inovação.
          Deste modo, acompanhamos as necessidades que emergem de um mundo em mudança: comunicar de
          forma rápida, segura, sustentável e eficiente.
        </p>
        <p>
          No âmbito da iniciativa “BdP Mais Solidário” promovida pelos trabalhadores do Banco de
          Portugal, desenvolvemos em parceria com a RedLight uma aplicação que aprimora a
          articulação entre a equipa técnica e renova a interação com as famílias, aproximando-nos
          delas.
        </p>
      </div>
    </div>
  );
}
