import Header from './Header';
import Intro from './Intro';
import About from './About';
import How from './How';
import Footer from './Footer';
import { landing } from './landing.module.scss';

export default function Landing() {
  return (
    <div className={landing}>
      <Header />
      <Intro />
      <About />
      <How />
      <Footer />
    </div>
  );
}
