import PropTypes from 'prop-types';
import {
  blue,
  blueonly,
  button,
  green,
  red,
  textonly,
  icononly,
  dropdown,
  white,
  create,
  greenDisabled,
  blueDisabled,
  borderless,
} from './button.module.scss';

var classes = {
  blue: blue,
  blueonly: blueonly,
  green: green,
  red: red,
  text: textonly,
  icononly: icononly,
  dropdown: dropdown,
  white: white,
  create: create,
  greenDisabled: greenDisabled,
  blueDisabled: blueDisabled,
  borderless: borderless,
};

export default function Button({ color, handle, img, text, type = 'button', disabled }) {
  return (
    <button
      onClick={handle}
      type={type}
      className={`${button} ${classes[color]}`}
      disabled={disabled}
    >
      {img ? <img src={'/images/icons/' + img + '.svg'} alt={img} width={24} height={24} /> : ''}
      <span>{text}</span>
    </button>
  );
}

Button.propTypes = {
  color: PropTypes.string,
  handle: PropTypes.func,
  img: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
};
