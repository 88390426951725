import Link from 'next/link';
import Button from '../../shared/Button';
import { footer, contact, logos, logoContainer } from './footer.module.scss';

export default function Footer() {
  return (
    <footer className={footer} id='contact'>
      <div className={contact}>
        <h2>Quer saber mais?</h2>
        <Link href='mailto:ajuda@appdaviseu.com'>
          <a target='_blank'>
            <Button color='blueonly' text='Contacte-nos' />
          </a>
        </Link>
      </div>
      <div className={logos}>
        <div className={logoContainer}>
          <p>Projeto Financiado Por:</p>
          <img src='/images/logos/bp.png' alt='bp-logo' />
        </div>
        <div className={logoContainer}>
          <p>Projeto Desenvolvido Por:</p>
          <img src='/images/logos/redlight.png' alt='redlight-logo' />
        </div>
      </div>
    </footer>
  );
}
