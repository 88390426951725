import {
  how,
  howContent,
  howMessage,
  howSteps,
  step,
  howCards,
  cardsContainer,
  card,
  annotation,
} from './how.module.scss';

export default function How() {
  return (
    <div className={how} id='how'>
      <div className={howContent}>
        <div className={howMessage}>
          <h2>Como funciona?</h2>
          <p>O processo é simples e pode aceder à nossa plataforma via web e smartphone.</p>
          <div className={howSteps}>
            <div className={step}>
              <h4>1.</h4>
              <p>Entre em contacto connosco para criarmos o seu registo na plataforma.</p>
            </div>
            <div className={step}>
              <h4>2.</h4>
              <p>Configure a sua conta.</p>
            </div>
            <div className={step}>
              <h4>3.</h4>
              <p>
                Comece a usar a plataforma, trocando mensagens e conteúdos com os nossos técnicos.
              </p>
            </div>
          </div>
        </div>
        <img src='/images/landing/girl.png' alt='girl-img' />
      </div>

      <div className={howCards}>
        <div className={cardsContainer}>
          <div className={card}>
            <img src='/images/icons/groups.svg' alt='groups-icon' />
            <h3>Grupos</h3>
            <p>
              Troque publicações, fotos e vídeos de forma privada e com segurança com os técnicos da
              APPDA Viseu.
            </p>
          </div>
          <div className={card}>
            <img src='/images/icons/messages.svg' alt='messages-icon' />
            <h3>Mensagens</h3>
            <p>
              Temos um canal aberto entre os técnicos e você. Pode trocar mensagens directas através
              do chat.
            </p>
          </div>
          <div className={card}>
            <img src='/images/icons/profile.svg' alt='profile-icon' />
            <h3>Cuidadores</h3>
            <p>
              Conheça os técnicos da APPDA Viseu que trabalham diariamente para melhorar a vida dos
              seus utentes.
            </p>
          </div>
        </div>
        <p className={annotation}>
          A plataforma APPDA Viseu assegura a sua privacidade e de todos os conteúdos que partilhar,
          permitindo apenas o acesso às pessoas relevantes para o seu processo.
        </p>
      </div>
    </div>
  );
}
