import { intro, introMessage } from './intro.module.scss';

export default function Intro() {
  return (
    <section className={intro} id='#intro'>
      <div className={introMessage}>
        <h1>APPDA</h1>
        <h3>Técnicos e Cuidadores</h3>
        <p>
          Numa plataforma inovadora, juntamos agora os técnicos e cuidadores da APPDA Viseu para nos
          dedicarmos a dar os melhores cuidados e acompanhamento a todos os nossos utentes.{' '}
        </p>
      </div>
      <img src='/images/landing/intro.png' alt='intro-img' />
    </section>
  );
}
