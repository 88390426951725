import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Button from '../../shared/Button';
import { header, headerVisible, headerHidden } from './header.module.scss';

var headerStyle = {
  header: header,
  headerVisible: headerVisible,
  headerHidden: headerHidden,
};

export default function Header() {
  const [showHeader, setShowHeader] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);

  const handleScroll = () => {
    setScrollPos(document.body.getBoundingClientRect().top);
    setShowHeader(document.body.getBoundingClientRect().top > scrollPos);

    if (scrollPos === 0) {
      headerStyle = header;
    } else if (showHeader) {
      headerStyle = headerVisible;
    } else {
      headerStyle = headerHidden;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <header className={`${header} && ${headerStyle}`}>
      <nav>
        <ul>
          <li>
            <a href='/#intro'>Início</a>
          </li>
          <li>
            <a href='/#about'>Sobre</a>
          </li>
          <li>
            <a href='/#how'>Como funciona</a>
          </li>
          <li>
            <a href='/#contact'>Contactos</a>
          </li>
        </ul>
        <Link href='/signin'>
          <a>
            <Button text='Entrar' />
          </a>
        </Link>
      </nav>
    </header>
  );
}
