import { useEffect } from 'react';
import Router from 'next/router';
import { useUserContext } from '../hooks/useUser';
import Landing from '../components/landing';

export default function Home() {
  const { isCaretaker, isLoggedIn, isSessionLoaded } = useUserContext();

  useEffect(() => {
    const { pathname } = Router;
    if (pathname == '/' && isSessionLoaded) {
      if (isLoggedIn) {
        isCaretaker ? Router.push('/groups?kid=1') : Router.push('/groups');
      }
    }
  }, [isCaretaker, isLoggedIn, isSessionLoaded]);

  return <Landing />;
}
